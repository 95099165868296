* {
  margin: 0;
  padding: 0;
  font-family: "Nunito Sans", sans-serif !important;
}

button.rec-dot {
  /* background-color: #272B36; */
  box-shadow: 0 0 1px 3px rgba(88, 88, 88, 0.89) !important;
  width: 7px !important;
  height: 7px !important;
}

.jump {
  transition: 1s;
  transform: scale(1, 1);
}
.jump:hover {
  transition: 0.5s;
  transform: scale(0.95,0.95);
}

.timer-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px; 
}

.marquee {
  background-color: #ddd;
  width: 500px;
  margin: 0 auto;
  overflow: hidden;
  white-space: nowrap;
}
.marquee span {
  display: inline-block;
  font-size: 20px;
  position: relative;
  left: 100%;
  animation: marquee 8s linear infinite;
}
.marquee:hover span {
  animation-play-state: paused;
}

.marquee span:nth-child(1) {
  animation-delay: 0s;
}
.marquee span:nth-child(2) {
  animation-delay: 0.8s;
}
.marquee span:nth-child(3) {
  animation-delay: 1.6s;
}
.marquee span:nth-child(4) {
  animation-delay: 2.4s;
}
.marquee span:nth-child(5) {
  animation-delay: 3.2s;
}

@keyframes marquee {
  0%   { left: 100%; }
  100% { left: -100%; }
}

button.rec-dot:hover,
button.rec-dot:active,
button.rec-dot:focus {
  box-shadow: 0 0 1px 3px rgba(88, 88, 88, 0.89);
}

button.eWjCzc {
  color: #eee !important;
  background-color:rgba(138, 138, 138, 0.1) !important;
}

button.eWjCzc:hover:enabled,
button.eWjCzc:active:enabled,
button.eWjCzc:focus:enabled {
  background-color: #57b21d !important;
}

button.hJJzxj:hover:enabled,
button.hJJzxj:active:enabled,
button.hJJzxj:focus:enabled{
background-color: #57b21d !important;
}
button.eAyARa {
background-color: rgba(138, 138, 138, 0.1) !important;
}

button.hJJzxj {
  color: rgba(254, 254, 254, 0.906) !important;
  background-color: rgba(171, 171, 171, 0.388) !important;
  width:35px !important;
  height: 35px !important;
  min-width: 35px !important;
  line-height: 35px !important;
  font-size:1.2em !important;
}
button.hJKYRZ {
    color: rgba(100, 100, 100, 0.388) !important;
    background-color: rgba(100, 100, 100, 0.388) !important;
    width: 35px !important;
    height: 35px !important;
    min-width: 35px !important;
    line-height: 35px !important;
    font-size: 1.2em !important;
}

button.jYxeHE {
  background-color: #eee !important;
  color: #eee !important;
}
button.hJKYRZ{
  background-color: rgba(191, 191, 191, 0.1);
}


.src-FlippingCard-styles-module__flipping-card--Azf-r{
  transition:1s !important
}

.bg-blur {
  backdrop-filter: blur(10px);
}

.blogcardEffect:hover {
 /* transform: scaleX(1.1);
 transform: scaleY(1.1);
 transition: 0.5s; */
 cursor: pointer;
}

.blogcardEffect {
  width: 100%;
}

.blogcard:hover {
  /* margin-left: 0% !important; */
  /* padding-left: 0% !important;  */
  /* margin-right: 0% !important; */
  border-top-left-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
 transition: 0.5s;

 /* background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%); */

}

.ril-prev-button  {
  z-index: 300px !important;
  /* margin-left: 100px !important; */
  left: 5vw !important;
}

.ril__navButtons {
  z-index: 300px !important;

} 
.ril__navButtonPrev {
  z-index: 300px !important;

}

.cardflip{
  height: 440px;
  width: 100% !important;
  margin:0 !important
}


.imageGrid {
  position: relative;
    height: 300,
}

/* .imageGrid:hover {
 transform: scale(0.9);
 transition: 0.5s;

} */

.react-share__ShareButton{
  width: max-content;
}

.text-block {
  position: absolute;
  bottom: 50%;
  /* right: 50%; */
  background-color: black;
  color: white;
}

.marginContainer {
  margin-left: 10%;
  margin-right: 10%;
}
.marginContainer1{
  margin-left:6%;
  margin-right: 6%;
}


.css-1w2oj2a-MuiAvatar-root {
  margin: 0% !important;
}

.rec-carousel-item {
  justify-content: space-between !important;
}

.sc-eCYdqJ  {
  justify-content: space-between !important;
}

.hHJTFD {
  margin: 0px !important;
  
}

.grUxXV {
  justify-content: space-between !important;
}

.krmNah {
  background-color: #eee !important;
}

.kdCslO {
  padding-bottom: 20px !important;

}





.marqueeStyle:not(.horizontal)::before {
  background:none !important;
  animation: marquee 5s linear infinite;
}

.marqueeStyle:not(.horizontal)::after {
  background:none !important;
  animation: marquee 5s linear infinite;
}


.maindiv{
  border: 2px solid black;
  overflow: hidden;
  white-space: nowrap;
}

.div1 {
  display: inline-block;
  animation: marquee 10s linear infinite;
}

.div2 {
  display: inline-block;
  animation: marquee2 10s linear infinite;
  animation-delay: 5s;
}

@keyframes marquee {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes marquee2 {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-200%);
  }
}

.vertical-menu {
  width: 500px;
  position: absolute;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  /* display: block; */
  padding: 12px;
  text-decoration: none;
  float:right
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.menu-style:hover {
  color: #a8a8a8 !important;
  /* padding-left: 5px !important; */
  transition: 0.2s

}

.footer-btn {
cursor: pointer;
color: #fff;
padding-top: 10px;
text-transform: capitalize;
}

.footer-btn:hover {
  padding-left: 3px !important;
  color: #57b21d !important;
  transition: 0.3s
}

.side-nav-icon {
  background-color:#4B5056 !important;
  border-radius: 5px !important;
}

.side-nav-icon:hover {
  background-color:#fba316 !important;
  border-radius: 20px !important;
  transition: 0.5s

  /* border-radius: 5px !important; */
}

.container {
 width: "100%" !important; 
}


.vertical-menu a.active {
  background-color: #04AA6D;
  color: white;
}


.indicator {
  width: 25px; 
  height: 40px;
  border: 2px solid #fff;
  border-radius: 50px; 
  position: relative;
}
.indicator:before {
  content: ""; 
  position: absolute;
  width: 5px; 
  height: 5px;
  background: #fff; 
  margin: auto;
  left: 0; 
  right: 0;
  top: 10px; 
  border-radius: 50%;
  animation: animate 1s linear infinite; 
}
@keyframes animate {
  0% {
      opacity: 1; top: 10px;
  }
  100% {
      opacity: 0; top: 40px;
      transform: scale(0.8);
  }
}


/* image over */

.ImageContainer {
  position: relative;
  height: 300,
}


.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #272b36;
  border-radius: 10px;
  cursor: pointer;
}

.imageGrid:hover .overlay {
  opacity: 1;
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}


